/* OpenLayers custom styling ONLY */
.ol-zoom,
.ol-full-screen,
.ol-rotate {
  top: 8px;
  left: inherit;
  right: 0px;
}

.ol-full-screen {
  top: 98px;
}

.ol-rotate {
  top: 175px;
}

.ol-control {
  border-radius: 0;
  padding: 0;
  background: 0;
}

.ol-control:hover,
.ol-control:focus {
  background: inherit;
}

.ol-control button {
  background: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: inherit;
  margin: inherit;
  box-shadow: inherit;
}

.ol-control button:hover,
.ol-control button:focus {
  background: inherit;
}

.ol-scale-line {
  left: inherit;
  right: 8px;
}

.ol-attribution li:not(:last-child):after {
  content: ", ";
}

.ol-overlaycontainer-stopevent {
  z-index: inherit !important;
}

.ol-overlay-container {
  z-index: 2000 !important;
}

#map:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0px 1px rgba(0, 0, 0, 0.1),
    inset 0 0px 2px rgba(0, 0, 0, 0.1), inset 0 0px 4px rgba(0, 0, 0, 0.1),
    inset 0 0px 8px rgba(0, 0, 0, 0.1);
  user-select: none;
  pointer-events: none;
}

.introjs-tooltip {
  width: 400px !important;
}
.introjs-skipbutton {
  color: #fff !important;
}
